import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AugmentedRoute, Button, Col, Form, List, Tag } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { useTheme } from 'styled-components';
import {
    Category,
    CategorySet,
    CategorySetType,
    MarketLanguage
} from '@olxeu-monetization/product-catalog-api-client';
import { BottomBar } from '../../components/BottomBar';
import { FormFieldType, FormInput } from '../../components/FormInput/FormInput';
import { PageHeader } from '../../components/PageHeader';
import { Section } from '../../components/Section';
import { FeatureFlag } from '../../featureFlags/types';
import { useFeatureFlag } from '../../helpers/useFeatureFlag';
import { Messages } from '../../intl';
import { CategoriesFormInput } from './CategoriesFormInput';
import {
    buildCategoryTree,
    mapCategorySetToFormValues,
    mapFormValuesToFormPayload
} from './helpers';
import { LocalizationsFormSection } from './LocalizationsFormSection';
import { CategorySetFormPayload, CategorySetFormValues } from './types';

const categorySetTypeNames = Object.values(CategorySetType).filter(
    (value) => value !== CategorySetType.Package
);

interface Props {
    title: string;
    categorySet?: CategorySet;
    categories: Category[];
    breadcrumbRoutes: AugmentedRoute[];
    marketLanguages: MarketLanguage[];
    submitting: boolean;
    onSubmit?: (product: CategorySetFormPayload) => void;
}

export const CategorySetFormLayout = ({
    title,
    categorySet,
    categories,
    breadcrumbRoutes,
    marketLanguages,
    submitting,
    onSubmit
}: Props) => {
    const [hasErrors, setHasErrors] = useState(false);
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();

    const { isFeatureFlag } = useFeatureFlag();

    const isCategorySetLocalesEnabled = isFeatureFlag(
        FeatureFlag.CategorySetLocales
    );

    const categoryTree = useMemo(() => {
        return buildCategoryTree(categories);
    }, [categories]);

    const [initialValues] = useState<CategorySetFormValues>(() => {
        return mapCategorySetToFormValues(marketLanguages, categorySet);
    });

    const handleFieldsChanged = (
        changedFields: FieldData[],
        allFields: FieldData[]
    ) => {
        const nextHasErrors = allFields.some((field) => field.errors?.length);
        setHasErrors(nextHasErrors);
    };

    const handleSubmit = (values: CategorySetFormValues) => {
        const categorySetPayload = mapFormValuesToFormPayload(
            values,
            categoryTree
        );

        onSubmit?.(categorySetPayload);
    };

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            onFieldsChange={handleFieldsChanged}
            initialValues={initialValues}
            layout="vertical"
            data-testid="category-set-form-layout"
            className="form-with-enhanced-labels"
        >
            <PageHeader
                title={title}
                tags={
                    categorySet ? (
                        <Tag color={theme.colors.tagIdentifier}>
                            {formatMessage(Messages['common.tag.id'], {
                                id: categorySet.id
                            })}
                        </Tag>
                    ) : undefined
                }
                breadcrumbRoutes={breadcrumbRoutes}
            />
            <Section
                title={formatMessage(Messages['common.label.properties'])}
                data-testid="category-set-properties"
            >
                <Col span={8}>
                    <FormInput
                        name="name"
                        label={formatMessage(Messages['common.label.name'])}
                        required={true}
                        type={FormFieldType.Input}
                        autoFocus={true}
                    />
                    {initialValues.type === undefined ? (
                        <FormInput
                            name="type"
                            label={formatMessage(Messages['common.label.type'])}
                            required={true}
                            type={FormFieldType.Select}
                            options={categorySetTypeNames}
                        />
                    ) : (
                        <List.Item>
                            <List.Item.Meta
                                title={formatMessage(
                                    Messages['common.label.type']
                                )}
                                description={initialValues.type}
                            />
                        </List.Item>
                    )}
                </Col>
            </Section>

            {isCategorySetLocalesEnabled && (
                <LocalizationsFormSection marketLanguages={marketLanguages} />
            )}

            <Section
                title={formatMessage(Messages['common.label.categories'])}
                data-testid="category-set-categories"
            >
                <Form.Item name="categoryIds">
                    <CategoriesFormInput categoryTree={categoryTree} />
                </Form.Item>
            </Section>
            <BottomBar>
                <Form.Item noStyle={true}>
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        disabled={submitting || hasErrors}
                    >
                        {formatMessage(Messages['common.button.save'])}
                    </Button>
                </Form.Item>
            </BottomBar>
        </Form>
    );
};
